body {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Roboto", sans-serif !important;
}

.ui {
  font-family: "Roboto", sans-serif !important;
}

/* Mobile */

@media only screen and (max-width: 767px) {
  .company-slogan {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
  }
  .company-name {
    font-size: 2rem;
    color: #ffffff;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
  }

  .big-heading {
    font-weight: 900;
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .company-slogan {
    font-size: 2rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.4rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1.2 rem;
  }
}

/* Computer/Desktop Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .company-slogan {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 4rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 1rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
  }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .company-slogan {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 4rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.4rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
  }
}

/* WideScreen Monitor */
@media only screen and (min-width: 1920px) {
  .company-slogan {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 5rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 3rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 2rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1.5rem;
  }
}

.carousel .slide {
  background: #ffffff !important;
}

.lp-background {
  background: url("https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/lp_banner-min.jpg?alt=media&token=7b62b4d2-0b1a-47ae-b982-ab1f34680843");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-background {
  background: url("https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/process_banner.jpg?alt=media&token=4dbaeaca-16f0-4ef4-a36c-c79fdf6c85a5");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.backscroll-image {
  position: fixed;
  z-index: -5;
  object-fit: cover;
}

.dark-tint {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 2;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.gradient-tint {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(black), to(transparent));
  background-image: linear-gradient(to right, black, transparent);
  opacity: 1;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.service-holder {
  position: relative !important;
  padding: 0% 0% !important;
}

.lp-service-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5% 5%;
  opacity: 0;
  color: white;
  display: table;
  vertical-align: middle;
  text-align: center;
  z-index: 3;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.hover-tint {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: 0%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 2;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.service-holder:hover > .hover-tint {
  width: 100%;
}

.service-holder:hover > .lp-service-text {
  opacity: 1;
}

.hover-image:hover {
  width: 0%;
  height: 0%;

  background-color: #f5bd20;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-filter: alpha(opacity=100);

  transform: scale(0.7, 0.7);
  -webkit-transform: scale(0.7, 0.7);
  -moz-transform: scale(0.7, 0.7);
  -o-transform: scale(0.7, 0.7);

  transition: all 750ms;
  -o-transition: all 750ms;
  -moz-transition: all 750ms;
  -webkit-transition: all 750ms;
}

.page-header-parent {
  position: relative;
  text-align: center;
  width: "100%";
  height: "100%";
}

.page-header-content {
  color: white;
  position: absolute;
  display: table-cell;
  top: 45%;
  left: 5%;
  font-weight: 600;
  vertical-align: middle;
}

.gallary-background {
  background: url("/public/assets/industry_image_1.jpg") !important;
  background-position: center;
}

.know-us-background {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fancy-boot-house.appspot.com/o/image_1.jpg?alt=media&token=c069067b-2e2d-4060-b2f3-9df85964d446");
  background-size: cover;
  background-position: bottom;
}

.pollution-control-background {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/pollution_control-min.jpg?alt=media&token=bf32ab57-32f2-44fa-88cf-fda7cc33690e");
  background-size: cover;
  background-position: bottom;
}

.contact-us-background {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/contact_us.jpg?alt=media&token=75c05e55-5536-41a5-85b0-5266b9bfe569");
  background-size: cover;
  background-position: bottom;
}

.verification-background {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/logic-education-solutions.appspot.com/o/image_3-min.jpg?alt=media&token=1766834b-3ba0-4e65-b8d5-bab3159e525d");
  background-size: cover;
  background-position: bottom;
}

.services-background {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/retroportal-studio.appspot.com/o/bg-image.webp?alt=media&token=a31a42b2-5483-4fa6-93cb-7e89c6b23a3b") !important;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.no-shadow-border {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -mox-box-shadow: none !important;
  border: none !important;
  border-radius: 0px !important;
}

.rotate_on_hover:hover {
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms; /* IE 9 */
  -webkit-transform: rotate(-40deg); /* Safari */
  transform: rotate(-40deg);
}

.shrink_on_hover:hover {
  transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out; /* IE 9 */
  -webkit-transform: scale(0.8, 0.8); /* Safari */
  transform: scale(0.8, 0.8);
}
.rise_on_hover:hover {
  font-weight: 700;
  transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out; /* IE 9 */
  -webkit-transform: translate(0px -4px) scale(1.1, 1.1); /* Safari */
  transform: translate(0px, -4px) scale(1.1, 1.1);
}

.pointer_on_hover {
  cursor: pointer !important;
}

#u_0_1 {
  display: none;
}

.shadowOne {
  z-index: 10;
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
}

.footer-gradient {
  color: white !important;
  background: #ff512f !important; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#232526),
    to(#414345)
  ) !important;
  background: linear-gradient(
    to right,
    #232526,
    #414345
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.top-navigation-bar {
  z-index: 5 !important;
  background-color: white !important;
  box-shadow: 1px 1px 4px black !important;
  -webkit-box-shadow: 1px 1px 4px black !important;
  -moz-box-shadow: 1px 1px 4px black !important;
}


.category_item:hover {
  background-color: grey !important;
  color: black !important;
  font-weight: bold !important;
  background-image: repeating-linear-gradient(
    to right,
    #ece9e6,
    #ffffff
  ) !important;
}

.category_holder {
  position: relative !important;
}

.category_menu {
  display: none;
  position: absolute !important;
  left: "2%";
  width: 95%;
  z-index: 10;
}

.category_menu:hover {
  display: block;
}

.category_holder:hover .category_menu {
  display: block !important;
}

.submenu_item {
  cursor: pointer;
}

.submenu_item:hover {
  font-weight: bold;
  color: #00adb5;
}

