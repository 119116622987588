.category_item:hover {
  background-color: grey !important;
  color: black !important;
  font-weight: bold !important;
  background-image: repeating-linear-gradient(
    to right,
    #ece9e6,
    #ffffff
  ) !important;
}

.category_holder {
  position: relative !important;
}

.category_menu {
  display: none;
  position: absolute !important;
  left: "2%";
  width: 95%;
  z-index: 10;
}

.category_menu:hover {
  display: block;
}

.category_holder:hover .category_menu {
  display: block !important;
}

.submenu_item {
  cursor: pointer;
}

.submenu_item:hover {
  font-weight: bold;
  color: #00adb5;
}
